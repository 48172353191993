import React from "react"
import { PageTitle, PageLayout } from "../components"
import { Link } from "gatsby"

export default () => {
  return (
    <PageLayout>
      <PageTitle>404: Not Found</PageTitle>
      <small>
        Learn more about me <Link to="/about">here</Link>!
      </small>
    </PageLayout>
  )
}
